.App {
  text-align: center;
  min-height: 100vh;
}

.AppBackground{
  min-height: 100vh;
  min-width: 100vh;
  background-image: url("login-background.jpg");
  background-size:     cover;
  background-repeat:   no-repeat;
  background-position: center center;
}